import { Fragment } from "react";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import { FaPen, FaTheaterMasks } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { IoLibrary } from "react-icons/io5";

import { env } from "~/env.mjs";

export default function NavDropDown() {
  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-black hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={
                      env.NEXT_PUBLIC_VERCEL_ENV === "production"
                        ? "https://inaction.biblish.com"
                        : "http://localhost:3003"
                    }
                    className={`${
                      active ? "bg-gray-300 text-black" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <FaTheaterMasks className="mr-2 size-5" />
                    Inaction
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={
                      env.NEXT_PUBLIC_VERCEL_ENV === "production"
                        ? "https://biblish.com"
                        : "http://localhost:3000"
                    }
                    className={`${
                      active ? "bg-gray-300 text-black" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <IoLibrary className="mr-2 size-5" />
                    Biblish
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={
                      env.NEXT_PUBLIC_VERCEL_ENV === "production"
                        ? "https://papertrail.biblish.com"
                        : "http://localhost:3001"
                    }
                    className={`${
                      active ? "bg-gray-300 text-black" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <GiNotebook className="mr-2 size-5" />
                    Papertrail
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={
                      env.NEXT_PUBLIC_VERCEL_ENV === "production"
                        ? "https://submissions.biblish.com"
                        : "http://localhost:3002"
                    }
                    className={`${
                      active ? "bg-gray-300 text-black" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <FaPen className="mr-2 size-5" />
                    Submissions
                  </Link>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
