"use client";

import Avatar, { type AvatarProps } from "boring-avatars";
import { useSession } from "next-auth/react";
import colors from "nice-color-palettes";

const ProfileIcon = ({
  size,
  avatar,
}: {
  size?: string;
  avatar?: {
    variant: AvatarProps["variant"];
    colors: number;
    name: string;
  } | null;
}) => {
  const session = useSession();
  let _avatar = session.data?.user.avatar;
  if (avatar === null) {
    _avatar = {
      colors: 0,
      name: "unknown",
      variant: "bauhaus",
    };
  }
  if (avatar) {
    _avatar = { ...avatar };
  }
  if (!_avatar) return null;
  return (
    <div className="rounded-full bg-white p-1">
      <Avatar
        size={size}
        colors={colors[_avatar?.colors ?? 0]}
        variant={_avatar?.variant}
        name={_avatar?.name}
      />
    </div>
  );
};

export default ProfileIcon;
