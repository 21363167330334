export default function Logo({
  className,
  size = "52",
}: {
  className?: string;
  size?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M209.275 284.909L153.409 317.576V418.751C151.063 420.339 149.516 423.045 149.516 426.107C149.516 428.741 150.658 431.092 152.464 432.722L203.295 344.254C200.264 341.516 199.433 336.929 201.551 333.24C203.98 328.997 209.369 327.545 213.584 329.99C217.799 332.435 219.242 337.859 216.813 342.101C214.695 345.8 210.324 347.378 206.441 346.104L155.631 434.54C156.482 434.822 157.375 435 158.33 435C162.961 435 166.719 431.384 167.072 426.818L254.199 376.189V310.992C234.359 323.699 208.299 308.536 209.286 284.941L209.275 284.909Z"
        fill="black"
      />
      <path
        d="M257.812 311.222V376.157L344.845 426.734C345.167 431.332 348.935 434.979 353.587 434.979C354.563 434.979 355.487 434.801 356.359 434.498L305.632 346.041C301.759 347.316 297.388 345.748 295.27 342.049C292.841 337.806 294.284 332.383 298.489 329.938C302.704 327.492 308.092 328.945 310.522 333.177C312.64 336.876 311.819 341.474 308.777 344.212L359.494 432.659C361.269 431.04 362.391 428.709 362.391 426.107C362.391 423.087 360.885 420.422 358.591 418.824V317.67L302.496 285.223C303.316 308.651 277.517 323.616 257.812 311.232V311.222Z"
        fill="black"
      />
      <path
        d="M349.787 264.72C344.918 264.72 340.983 260.75 340.983 255.859C340.983 250.969 344.928 246.998 349.787 246.998C354.033 246.998 357.563 250.018 358.404 254.041H461C460.159 250.018 456.629 246.998 452.383 246.998C450.784 246.998 449.31 247.457 448.012 248.21L360.418 197.309L304.365 229.923C325.129 240.905 325.077 271.137 304.303 282.057L360.335 314.472L448.002 263.529C449.289 264.282 450.774 264.741 452.372 264.741C456.619 264.741 460.149 261.721 460.99 257.698H358.394C357.553 261.721 354.023 264.741 349.776 264.741L349.787 264.72Z"
        fill="black"
      />
      <path
        d="M254.157 200.517V135.561L167.03 84.921C166.553 80.4798 162.857 77 158.32 77C157.417 77 156.555 77.1463 155.735 77.4075L206.358 165.688C210.23 164.413 214.601 165.98 216.719 169.679C219.149 173.922 217.706 179.345 213.501 181.791C209.286 184.236 203.897 182.783 201.468 178.551C199.35 174.852 200.17 170.254 203.212 167.516L152.547 79.1631C150.689 80.7933 149.505 83.1863 149.505 85.8614C149.505 88.9232 151.052 91.6297 153.399 93.2181V194.121L209.452 226.641C208.517 203.119 234.4 188.061 254.157 200.506V200.517Z"
        fill="black"
      />
      <path
        d="M302.683 226.673L358.591 194.142V93.1345C359.629 92.4135 360.553 91.473 361.228 90.2922C363.356 86.5825 362.453 82.0159 359.38 79.2885L308.705 167.474C311.736 170.212 312.567 174.8 310.449 178.489C308.02 182.731 302.631 184.184 298.416 181.738C294.201 179.293 292.758 173.87 295.187 169.627C297.305 165.928 301.676 164.35 305.559 165.625L356.224 77.4912C352.372 76.2685 348.074 77.7733 345.977 81.4412C345.333 82.5698 344.98 83.7715 344.855 84.9837L257.812 135.571V200.747C277.6 188.092 303.576 203.171 302.694 226.683L302.683 226.673Z"
        fill="black"
      />
      <path
        d="M207.51 229.734L151.603 197.288L63.988 248.199C62.7006 247.447 61.216 246.987 59.6172 246.987C55.3709 246.987 51.841 250.007 51 254.03H153.596C154.437 250.007 157.967 246.987 162.213 246.987C167.082 246.987 171.017 250.958 171.017 255.848C171.017 260.739 167.072 264.71 162.213 264.71C157.967 264.71 154.437 261.69 153.596 257.667H51C51.841 261.69 55.3709 264.71 59.6172 264.71C61.216 264.71 62.6903 264.25 63.988 263.498L151.582 314.399L207.469 281.722C186.86 270.708 186.912 240.665 207.521 229.713L207.51 229.734Z"
        fill="black"
      />
      <path
        d="M256.078 285.86C272.523 285.86 285.854 272.442 285.854 255.89C285.854 239.338 272.523 225.92 256.078 225.92C239.633 225.92 226.302 239.338 226.302 255.89C226.302 272.442 239.633 285.86 256.078 285.86Z"
        fill="black"
      />
    </svg>
  );
}
